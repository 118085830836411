import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assect/scss/style.scss";
import "./assect/css/materialdesignicons.min.css";
import ScrollTop from "./components/scrollTop";
import Index from "./pages/index";
import Buy from "./pages/buy";
import Sell from "./pages/sell";
import Grid from "./pages/listing/grid";
import ListSidebar from "./pages/listing/list-sidebar";
import PropertyDetails from "./pages/listing/property-detail";
import Features from "./pages/features";
import Pricing from "./pages/pricing";
import Privacy from "./pages/privacy";
import Projekty from "./pages/projekty";
import ProjectDetail from "./pages/projekt-detail";
import ContactUs from "./pages/contactus";
import Comingsoon from "./pages/Special/comingsoon";
import Maintenance from "./pages/Special/maintenance";
import Error from "./pages/Special/error";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Notify Google Analytics of the route change
    const trackPageview = (url) => {
      if (window.gtag) {
        window.gtag("config", "G-0BWV96TFZJ", {
          page_path: url,
        });
      }
    };

    trackPageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/spoluprace" element={<Sell />} />
        <Route path="/nabidka" element={<ListSidebar />} />
        <Route path="/detail-nemovitosti" element={<PropertyDetails />} />
        <Route path="/detail-nemovitosti/:id" element={<PropertyDetails />} />
        <Route path="/projekty" element={<Projekty />} />
        <Route path="/projekty-detail" element={<ProjectDetail />} />
        <Route path="/projekt-detail/:slug" element={<ProjectDetail />} />
        <Route path="/kontakt" element={<ContactUs />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <ScrollTop />
    </>
  );
}

export default App;
