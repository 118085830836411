import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

// Initialize Google Analytics
const initializeGoogleAnalytics = () => {
  if (window.gtag) {
    window.gtag("config", "G-0BWV96TFZJ", {
      page_path: window.location.pathname + window.location.search,
    });
  }
};

// Track web vitals in Google Analytics
const logWebVitals = ({ id, name, value }) => {
  if (window.gtag) {
    window.gtag("event", name, {
      event_category: "Web Vitals",
      event_label: id,
      value: Math.round(name === "CLS" ? value * 1000 : value), // Convert CLS to milliseconds
      non_interaction: true, // Prevent affecting bounce rate
    });
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// Initialize Analytics after rendering the app
initializeGoogleAnalytics();

// Start measuring performance
reportWebVitals(logWebVitals);
