import React, { useEffect, useState } from "react";

const PropertyFilters = ({ filters, onFilterChange, onSubmit }) => {
  const [cities, setCities] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const fetchFilterData = async () => {
    try {
      const response = await fetch(
        "https://cms.petrazarecka.cz/wp-json/wp/v2/nemovitost"
      );
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();

      const uniqueCities = [
        ...new Set(data.map((item) => item.acf?.property_adress?.city || "")),
      ].filter(Boolean);
      const uniqueStatuses = [
        ...new Set(data.map((item) => item.acf?.status || "")),
      ].filter(Boolean);

      setCities(uniqueCities);
      setStatuses(uniqueStatuses);
    } catch (error) {
      console.error("Error fetching filter data:", error);
    }
  };

  useEffect(() => {
    fetchFilterData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onFilterChange(name, value);
  };

  return (
    <form onSubmit={onSubmit} className="card bg-white p-4 rounded-3 shadow">
      <div className="mb-2">
        <label htmlFor="searchTerm" className="form-label">
          Vyhledejte
        </label>
        <input
          type="text"
          id="searchTerm"
          name="searchTerm"
          value={filters.searchTerm}
          onChange={handleInputChange}
          placeholder="Vyhledejte..."
          className="form-control"
        />
      </div>
      <div className="mb-2">
        <label htmlFor="propertyType" className="form-label">
          Prodej/Pronájem
        </label>
        <select
          id="propertyType"
          name="propertyType"
          value={filters.propertyType}
          onChange={handleInputChange}
          className="form-select"
        >
          <option value="All">Vše</option>
          <option value="Prodej">Prodej</option>
          <option value="Pronájem">Pronájem</option>
        </select>
      </div>
      <div className="mb-2">
        <label htmlFor="category" className="form-label">
          Kategorie
        </label>
        <select
          id="category"
          name="category"
          value={filters.category}
          onChange={handleInputChange}
          className="form-select"
        >
          <option value="All">Vše</option>
          <option value="Byt">Byt</option>
          <option value="Apartmán">Apartmán</option>
          <option value="Ostatní">Ostatní</option>
        </select>
      </div>
      <div className="mb-2">
        <label htmlFor="city" className="form-label">
          Město
        </label>
        <select
          id="city"
          name="city"
          value={filters.city}
          onChange={handleInputChange}
          className="form-select"
        >
          <option value="All">Vše</option>
          {cities.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-2">
        <label htmlFor="status" className="form-label">
          Stav
        </label>
        <select
          id="status"
          name="status"
          value={filters.status}
          onChange={handleInputChange}
          className="form-select"
        >
          <option value="All">Vše</option>
          {statuses.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </select>
      </div>
      <button type="submit" className="btn btn-primary mt-3 w-100">
        Zobrazit nemovitosti
      </button>
    </form>
  );
};

export default PropertyFilters;
