import React, { useState } from "react";
import { Link } from "react-router-dom";

const PropertyCard = ({ item }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  if (!item || !item.acf) {
    console.error("Invalid item data:", item);
    return null;
  }

  const {
    property_name,
    property_photos,
    property_adress,
    property_description,
    floor_area,
    land_area,
    number_bedroom,
    number_bathroom,
    property_price,
    sell_rent,
    status,
  } = item.acf;

  const firstImageUrl =
    property_photos && property_photos.length > 0
      ? property_photos[0].url
      : "path/to/default/image.jpg";

  const getStatusClassName = (status) => {
    switch (status) {
      case "K dispozici":
        return "bg-success";
      case "Rezervace":
        return "bg-warning";
      case "Prodáno":
        return "bg-danger";
      default:
        return "bg-secondary";
    }
  };

  return (
    <div className="col-12">
      <div className="card property property-list border-0 shadow position-relative overflow-hidden rounded-3">
        <div className="d-md-flex">
          <div
            className={`property-image position-relative overflow-hidden shadow flex-md-shrink-0 rounded-3 m-2 ${
              status === "Prodáno" ? "grayscale" : ""
            }`}
          >
            <img
              src={firstImageUrl}
              className="img-fluid h-100 w-100"
              alt={property_name}
            />
            {item.isExclusive && (
              <div className="exclusive-badge position-absolute top-0 start-0 bg-warning text-dark p-2">
                EXKLUZIVNÍ
              </div>
            )}
            {item.isDeveloperProject && (
              <div className="exclusive-badge position-absolute top-0 end-0 bg-info text-white p-2">
                DEVELOPERSKÝ PROJEKT
              </div>
            )}
          </div>
          <div className="card-body content p-3">
            <Link
              to={`/detail-nemovitosti/${item.id}`}
              className="title fs-5 text-dark fw-medium"
            >
              <span className="badge bg-primary">{sell_rent}</span>{" "}
              <span className={`badge ${getStatusClassName(status)}`}>
                {status}
              </span>
              <br />
              {property_name}
            </Link>
            {property_adress && (
              <p className="text-muted mb-2">
                {property_adress.street && `${property_adress.street}, `}
                {property_adress.city && `${property_adress.city}, `}
                {property_adress.psc && property_adress.psc}
              </p>
            )}
            {property_description && (
              <p className="text-muted">
                {showFullDescription
                  ? property_description
                  : `${property_description.slice(0, 135)}...`}
                {property_description.length > 135 && (
                  <button
                    onClick={() => setShowFullDescription(!showFullDescription)}
                    className="btn btn-link p-0"
                  ></button>
                )}
              </p>
            )}
            <ul className="list-unstyled mt-3 py-3 border-top border-bottom d-flex align-items-center justify-content-between">
              {floor_area && (
                <li className="d-flex align-items-center me-3">
                  <i className="mdi mdi-floor-plan fs-5 me-2 text-primary"></i>
                  <span className="text-muted">{floor_area} m²</span>
                </li>
              )}
              {land_area && (
                <li className="d-flex align-items-center me-3">
                  <i className="mdi mdi-arrow-expand-all fs-5 me-2 text-primary"></i>
                  <span className="text-muted">{land_area} m²</span>
                </li>
              )}
              {number_bedroom && (
                <li className="d-flex align-items-center me-3">
                  <i className="mdi mdi-bed fs-5 me-2 text-primary"></i>
                  <span className="text-muted">{number_bedroom}</span>
                </li>
              )}
              {number_bathroom && (
                <li className="d-flex align-items-center">
                  <i className="mdi mdi-shower fs-5 me-2 text-primary"></i>
                  <span className="text-muted">{number_bathroom}</span>
                </li>
              )}
            </ul>
            {property_price && (
              <ul className="list-unstyled d-flex justify-content-between mt-2 mb-0">
                <li className="list-inline-item mb-0">
                  <span className="text-muted">Cena</span>
                  <p className="fw-medium mb-0">
                    {new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: "CZK",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(property_price)}
                  </p>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
