// src/contactInfo.js

export const contactInfo = {
  address: {
    line1: "Chelčického 967/10",
    line2: "Hradec Králové",
    line3: "500 02",
    mapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.1326585620964!2d14.424640499999997!3d50.0838031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b94ecf7f598b7%3A0x971ae5e5abc5f6cf!2sGoldfingers%20Prague!5e0!3m2!1scs!2scz!4v1715861030113!5m2!1scs!2scz",
  },
  email: "reality@petrazarecka.cz",
  phone: "+420 775 771 015",
};
