import React, { useEffect, useState } from "react";
import PropertyCard from "./PropertyCard";
import LoadingSpinner from "./search/LoadingSpinner";
import ErrorMessage from "./search/ErrorMessage";
import NoResultsMessage from "./search/NoResultsMessage";

const PropertyList = ({ filters }) => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const itemsPerPage = 5;

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    let allProperties = [];
    let page = 1;
    const perPage = 100;

    try {
      while (true) {
        const response = await fetch(
          `https://cms.petrazarecka.cz/wp-json/wp/v2/nemovitost?page=${page}&per_page=${perPage}`
        );
        if (!response.ok) {
          if (response.status === 400 || response.status === 404) break;
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        allProperties = [...allProperties, ...data];

        if (data.length < perPage) break;
        page++;
      }

      // Enrich data with flags
      const enrichedData = allProperties.map((item) => ({
        ...item,
        isExclusive: parseInt(item.acf?.property_price || "0", 10) > 15000000,
        isDeveloperProject: item.acf?.dev_proj === "Ano",
      }));

      setProperties(enrichedData);
    } catch (error) {
      setError("Chyba při načítání dat.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, selectedStatus]);

  // Filter properties based on applied filters
  // Filter and sort properties based on applied filters
  const filteredProperties = properties
    .filter((property) => {
      const { searchTerm, propertyType, category, city, status } = filters;

      if (
        searchTerm &&
        !property.acf?.property_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return false;
      }
      if (propertyType !== "All" && property.acf?.sell_rent !== propertyType) {
        return false;
      }
      if (category !== "All" && property.acf?.property_type !== category) {
        return false;
      }
      if (city !== "All" && property.acf?.property_adress?.city !== city) {
        return false;
      }
      if (status !== "All" && property.acf?.status !== status) {
        return false;
      }
      if (selectedStatus !== "All" && property.acf?.status !== selectedStatus) {
        return false;
      }
      return true;
    })
    .sort((a, b) => {
      // Ensure properties with status "prodáno" are last
      if (a.acf?.status === "Prodáno" && b.acf?.status !== "Prodáno") return 1;
      if (a.acf?.status !== "Prodáno" && b.acf?.status === "Prodáno") return -1;
      return 0; // Keep original order for other properties
    });

  // Pagination calculation
  const totalPages = Math.ceil(filteredProperties.length / itemsPerPage);
  const currentProperties = filteredProperties.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      {error && <ErrorMessage message={error} />}

      {/* Status Filters */}
      <div className="mb-4 text-center">
        {["All", "K dispozici", "Rezervováno", "Prodáno"].map((status) => (
          <button
            key={status}
            className={`btn mx-1 mb-2 ${
              selectedStatus === status ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setSelectedStatus(status)}
          >
            {status === "All" ? "Vše" : status}
          </button>
        ))}
      </div>

      {/* Display messages or properties */}
      {!loading && !error && filteredProperties.length === 0 && (
        <NoResultsMessage />
      )}
      {!loading && !error && filteredProperties.length > 0 && (
        <>
          {/* Property List */}
          <div className="row g-4">
            {currentProperties.map((item) => (
              <PropertyCard key={item.id} item={item} />
            ))}
          </div>

          {/* Pagination */}
          <div className="row">
            <div className="col-12 mt-4 pt-2">
              <ul className="pagination justify-content-center mb-0">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    {"<"}
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    {">"}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PropertyList;
