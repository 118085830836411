import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import bg2 from "../assect/images/bg/02.webp";
import bg7 from "../assect/images/bg/07.webp";
import Footer from "../components/footer";

const LoadingSpinner = () => (
  <div className="text-center my-5">
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only"></span>
    </div>
  </div>
);

export default function Projekty() {
  const [allPosts, setAllPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const postsPerPage = 9; // Display 9 posts per page
  const apiURL = "https://cms.petrazarecka.cz/wp-json/wp/v2";

  const fetchAllPosts = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiURL}/nemovitost?_embed&per_page=100`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const filteredData = data.filter((post) => post.acf?.dev_proj === "Ano");
      const formattedData = filteredData.map((post) => ({
        id: post.id,
        title: post.acf?.property_name || "Neznámé jméno projektu",
        slug: post.slug,
        description: post.acf?.property_description || "Popis není k dispozici",
        images:
          Array.isArray(post.acf?.property_photos) &&
          post.acf.property_photos.length
            ? post.acf.property_photos.map((photo) => photo.url)
            : [bg7],
        type: post.acf?.property_type || "Neurčený typ",
      }));
      setAllPosts(formattedData);
    } catch (error) {
      setError(
        "An error occurred while fetching posts. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPosts();
  }, []);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = allPosts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(allPosts.length / postsPerPage);

  if (loading) return <LoadingSpinner />;
  if (error)
    return (
      <div className="text-center my-5">
        <p className="text-danger">{error}</p>
      </div>
    );

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-light"
      />
      <section
        className="bg-half-120 d-table w-100"
        style={{ backgroundImage: `url(${bg2})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Developerské projekty
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row g-4">
            {currentPosts.map((post) => (
              <div className="col-lg-4 col-md-6" key={post.id}>
                <Link
                  to={`/projekt-detail/${post.slug}`}
                  className="text-decoration-none"
                >
                  <div className="card blog shadow-lg rounded-3 overflow-hidden border-0 h-100">
                    <div className="position-relative">
                      <img
                        src={post.images[0]}
                        className="img-fluid w-100"
                        alt={post.title}
                        style={{ height: "300px", objectFit: "cover" }}
                      />
                      <div className="badge bg-success position-absolute top-0 start-0 m-3">
                        {post.type}
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="title text-dark">{post.title}</h5>
                      <p className="text-muted mt-2">
                        {post.description.length > 100
                          ? `${post.description.slice(0, 100)}...`
                          : post.description}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          {/* Pagination */}
          <div className="row">
            <div className="col-12 mt-4 pt-2">
              <ul className="pagination justify-content-center mb-0">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage - 1)}
                  >
                    {"<"}
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage + 1)}
                  >
                    {">"}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
