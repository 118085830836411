import React from "react";
import { Link } from "react-router-dom";
import { FiMail, FiMapPin, FiPhone } from "../assect/icons/vander";
import logoLight from "../assect/images/logo-light.png";
import { contactInfo } from "./contactInfo";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-py-60 footer-border">
                <div className="row">
                  <div className="col-lg-5 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                    <Link to="#" className="logo-footer">
                      <img src={logoLight} alt="" />
                    </Link>
                    <p className="mt-4 text-justify">
                      Můj servis směrem k Vám je stejný ať už se jedná o
                      nemovitost za pár milonů a nebo nemovitost za desítky
                      milionů korun. V mém portfoliu najdete i spolupráce v
                      oblasti developerských a investičních projektů. Zaměřuji
                      se na východní Čechy, horská střediska jako jsou Krkonoše
                      i Orlické hory, Středočeský kraj a Prahu.
                    </p>
                  </div>

                  <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h5 className="footer-head">Menu</h5>
                    <ul className="list-unstyled footer-list mt-4">
                      <li>
                        <Link to="/" className="text-foot">
                          <i className="mdi mdi-chevron-right align-middle me-1"></i>{" "}
                          Domů
                        </Link>
                      </li>
                      <li>
                        <Link to="/nabidka" className="text-foot">
                          <i className="mdi mdi-chevron-right align-middle me-1"></i>{" "}
                          Nabídka nemovitostí
                        </Link>
                      </li>
                      <li>
                        <Link to="/spoluprace" className="text-foot">
                          <i className="mdi mdi-chevron-right align-middle me-1"></i>{" "}
                          Spolupráce
                        </Link>
                      </li>
                      <li>
                        <Link to="/projekty" className="text-foot">
                          <i className="mdi mdi-chevron-right align-middle me-1"></i>{" "}
                          Developerské projekty
                        </Link>
                      </li>
                      <li>
                        <Link to="/kontakt" className="text-foot">
                          <i className="mdi mdi-chevron-right align-middle me-1"></i>{" "}
                          Kontakt
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h5 className="footer-head">Kontakt</h5>

                    {/* <div className="d-flex mt-4">
                      <FiMapPin className="fea icon-sm text-primary mt-1 me-3" />
                      <div className="">
                        <p className="mb-2">
                          {contactInfo.address.line1}, <br />
                          {contactInfo.address.line2} <br />
                          {contactInfo.address.line3}
                        </p>
                        <a
                          href="https://maps.app.goo.gl/rSj1AH6HUwmyVpi5A"
                          target="_blank"
                        >
                          Zobrazit na mapách google
                        </a>
                      </div>
                    </div> */}

                    <div className="d-flex mt-4">
                      <FiMail className="fea icon-sm text-primary mt-1 me-3" />
                      <Link
                        to={`mailto:${contactInfo.email}`}
                        className="text-foot"
                      >
                        {contactInfo.email}
                      </Link>
                    </div>

                    <div className="d-flex mt-4">
                      <FiPhone className="fea icon-sm text-primary mt-1 me-3" />
                      <Link
                        to={`tel:${contactInfo.phone}`}
                        className="text-foot"
                      >
                        {contactInfo.phone}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-py-30 footer-bar">
          <div className="container text-center">
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <p className="mb-0">
                    © {new Date().getFullYear()} Petra Zářecká - Reality
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
