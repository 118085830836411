import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logoDark from "../assect/images/logo-dark.png";
import logoLight from "../assect/images/logo-light.png";

export default function Navbar({ navClass, logolight, menuClass }) {
  const [scroll, setScroll] = useState(false);
  const [isMenu, setisMenu] = useState(false);
  const location = useLocation(); // Hook to get the current path

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });

    return () => {
      window.removeEventListener("scroll", () => {
        setScroll(window.scrollY > 50);
      });
    };
  }, []);

  // Toggle menu
  const toggleMenu = () => {
    setisMenu(!isMenu);
  };

  // Function to check if a route is active
  const isActive = (path) => {
    if (
      path === "/nabidka" &&
      location.pathname.startsWith("/detail-nemovitosti")
    ) {
      return "active"; // Highlight Nabídka nemovitostí for detail pages
    }
    return location.pathname === path ? "active" : "";
  };

  return (
    <>
      <header
        id="topnav"
        className={`${scroll ? "nav-sticky" : ""} ${navClass}`}
      >
        <div className="container">
          {logolight === true ? (
            <Link className="logo" to="/">
              <span className="logo-light-mode">
                <img src={logoDark} className="l-dark" alt="Logo Dark" />
                <img src={logoLight} className="l-light" alt="Logo Light" />
              </span>
              <img src={logoLight} className="logo-dark-mode" alt="Logo Dark" />
            </Link>
          ) : (
            <Link className="logo" to="/">
              <img
                src={logoDark}
                className="logo-light-mode"
                alt="Logo Light"
              />
              <img src={logoLight} className="logo-dark-mode" alt="Logo Dark" />
            </Link>
          )}

          <div className="menu-extras">
            <div className="menu-item">
              <Link
                className={`navbar-toggle ${isMenu ? "open" : ""}`}
                id="isToggle"
                onClick={() => toggleMenu()}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>

          <div id="navigation" style={{ display: isMenu ? "block" : "none" }}>
            <ul className={menuClass}>
              <li className={`has-submenu parent-menu-item ${isActive("/")}`}>
                <Link to="/">Domů</Link>
              </li>
              <li className={`hover-white ${isActive("/nabidka")}`}>
                <Link to="/nabidka">Nabídka nemovitostí</Link>
              </li>
              <li
                className={`has-submenu parent-menu-item ${isActive(
                  "/projekty"
                )}`}
              >
                <Link to="/projekty">Developerské projekty</Link>
              </li>
              <li className={isActive("/spoluprace")}>
                <Link to="/spoluprace" className="sub-menu-item">
                  Spolupráce
                </Link>
              </li>
              <li className={isActive("/kontakt")}>
                <Link to="/kontakt" className="sub-menu-item">
                  Kontakt
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}
