import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assect/images/logo-dark.png";
import errorImg from "../../assect/images/error.png";

export default function Error() {
  return (
    <section className="position-relative bg-soft-primary">
      <div className="container">
        <div className="row">
          <div className="col-12 p-0">
            <div className="d-flex flex-column min-vh-100 justify-content-center px-md-5 py-5 px-4">
              <div className="text-center">
                <Link to="/">
                  <img src={logo} alt="" width={"300px"} />
                </Link>
              </div>
              <div className="title-heading text-center my-auto">
                <img src={errorImg} className="img-fluid" alt="" />
                <h1 className="mb-3 mt-5 text-dark">
                  Taková stránka na mém webu není . . .
                </h1>
                <p className="text-muted">
                  Vypadá to, že to co hledáte se nenachází na mých webových
                  stránkách - zkuste se vrátit na domovskou stránku
                </p>

                <div className="mt-4">
                  <Link to="/" className="btn btn-primary">
                    VRÁTIT SE ZPĚT
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
