import React from "react";
import { Link } from "react-router-dom";

import {
  FiHexagon,
  FiCalendar,
  FiCamera,
  FiClock,
  FiFileText,
  FiPhoneIncoming,
  FiCheck,
} from "../assect/icons/vander";

export default function AboutUs() {
  const aboutData = [
    {
      icon: FiCalendar,
      title: "1",
      desc: "Domluvíme si schůzku, kde se osobně setkáme a já si prohlédnu Vaši nemovitost. Společně zhodnotíme její stav a probereme Vaše představy a potřeby.",
    },
    {
      icon: FiFileText,
      title: "2",
      desc: "Po osobní schůzce a prohlídce Vaší nemovitosti Vám připravím strategii prodeje a stanovím optimální prodejní cenu.",
    },
    {
      icon: FiCamera,
      title: "3",
      desc: "Připravím pro Vás individuální marketingový plán, který může zahrnovat fotostaging, homestaging, vizualizace, profesionální fotografie a videoprohlídku.",
    },
    {
      icon: FiClock,
      title: "4",
      desc: "Věnuji hodně času správnému nastavení inzerce a propagace na realitních portálech, sociálních sítích a případně i v tištěných médiích.",
    },
    {
      icon: FiPhoneIncoming,
      title: "5",
      desc: "Po zveřejnění inzerce Vás budu pravidelně informovat o vývoji prodeje a budeme společně konzultovat další kroky k úspěšnému prodeji.",
    },
    {
      icon: FiCheck,
      title: "6",
      desc: "Zajistím kompletní právní servis, včetně advokátní úschovy.",
    },
  ];
  return (
    <>
      <div className="row justify-content-center">
        <div className="col">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title fw-bold mb-3">SPOLUPRÁCE SE MNOU</h4>
          </div>
        </div>
      </div>

      <div className="row  mt-0">
        {aboutData.map((item, index) => {
          const Icon = item.icon;
          return (
            <div className="col-md-4 mb-5" key={index}>
              <div className="position-relative features text-center mx-lg-4 px-md-1">
                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                  <FiHexagon className="hexagon" />
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <Icon className="fea icon-m-md text-primary" />
                  </div>
                </div>

                <div className="mt-4">
                  <Link to="#" className="fw-medium title text-dark fs-5">
                    {item.title}
                  </Link>
                  <p className="text-muted text-justify mt-1 mb-0">
                    {item.desc}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
