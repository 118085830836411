import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import background from "../../assect/images/bg.png";
import bg1 from "../../assect/images/bg/01.webp";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import AboutUs from "../../components/about";
const LoadingSpinner = () => (
  <div className="text-center my-5">
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only"></span>
    </div>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="alert alert-danger text-center my-5" role="alert">
    {message}
  </div>
);

export default function Index() {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProperties = async () => {
    const apiURL = `https://cms.petrazarecka.cz/wp-json/wp/v2/nemovitost?cacheBuster=${new Date().getTime()}`;
    try {
      const response = await fetch(apiURL);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const propertiesArray = Array.isArray(data) ? data : [data];
      setProperties(propertiesArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Ups. Něco se nezdařilo - prosím aktualizujte stránku.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  const getStatusClassName = (status) => {
    switch (status) {
      case "K dispozici":
        return "bg-success";
      case "Rezervace":
        return "bg-warning";
      case "Prodáno":
        return "bg-danger";
      default:
        return "bg-secondary";
    }
  };

  return (
    <>
      <Navbar navClass="defaultscroll sticky" menuClass="navigation-menu" />

      <section
        className="bg-half-120 d-table w-100 pb-0"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "bottom",
        }}
      >
        <div className="bg-overlay bg-primary-gradient-overlay z-n1"></div>
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-12 text-center">
              <div className="title-heading text-center">
                <h4 className="heading fw-bold title-dark mb-5">
                  Dovedu Vás k{" "}
                  <span className="text-primary">vysněnému bydlení</span>
                </h4>
                <p className="para-desc text-justify title-dark mx-auto">
                  <b>Kdo jsem:</b> Jmenuji se Petra Zářecká a v oblasti realit
                  pracuji již několik let. Jsem máma, úspěšná realitní makléřka,
                  cílevědomá, pečlivá, veselá, zodpovědná a vždy se snažím hrát
                  fair play.
                </p>
                <p className="para-desc text-justify title-dark mx-auto">
                  Můj přístup k Vám je vždy stejný, ať už se jedná o nemovitost
                  za pár milionů, nebo o nemovitost za desítky milionů korun. V
                  mém portfoliu najdete také spolupráce v oblasti developerských
                  a investičních projektů. Zaměřuji se na východní Čechy, horská
                  střediska, jako jsou Krkonoše a Orlické hory, Středočeský kraj
                  a Prahu.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="position-relative overflow-hidden shadow rounded-3">
                <img
                  src={bg1}
                  alt="Petra Zarecka"
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <AboutUs />
          <div className="text-center mt-4">
            <Link to="/spoluprace" className="btn btn-primary">
              Více o spolupráci se mnou
            </Link>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title fw-bold mb-3">NEMOVITOSTI</h4>
                <p className="para-desc title-dark mb-2 mx-auto">
                  Pravidelně přidávám nové nemovitosti, abyste měli z čeho
                  vybírat. Podívejte se na ty nejnovější z nich.
                </p>
              </div>
            </div>
          </div>

          {loading ? (
            <LoadingSpinner />
          ) : error ? (
            <ErrorMessage message={error} />
          ) : (
            <div className="row g-4">
              {properties.slice(0, 9).map((item, index) => {
                const {
                  property_name,
                  property_photos,
                  property_adress,
                  property_price,
                  sell_rent,
                  dev_proj,
                  status,
                } = item.acf || {};

                const firstImage =
                  property_photos && property_photos.length > 0
                    ? property_photos[0].url
                    : null;

                return (
                  <div className="col-md-4" key={index}>
                    <Link
                      to={`/detail-nemovitosti/${item.id}`}
                      className="card property border-0 shadow position-relative overflow-hidden rounded-3 text-decoration-none"
                      style={{ display: "block" }}
                    >
                      <div className="property-image position-relative overflow-hidden d-flex align-items-center">
                        {firstImage ? (
                          <img
                            src={firstImage}
                            className="img-fluid w-100"
                            alt={property_name}
                            style={{
                              minHeight: "350px",
                              objectFit: "cover",
                              filter:
                                status === "Prodáno"
                                  ? "grayscale(100%)"
                                  : "none",
                            }}
                          />
                        ) : (
                          <div
                            className="img-placeholder d-flex align-items-center justify-content-center"
                            style={{
                              minHeight: "200px",
                              backgroundColor: "#f8f9fa",
                            }}
                          >
                            Není k dispozici žádný obrázek
                          </div>
                        )}
                        {parseInt(property_price, 10) > 15000000 && (
                          <div className="exclusive-badge position-absolute top-0 start-0 bg-warning text-dark p-2">
                            EXKLUZIVNÍ
                          </div>
                        )}
                        {dev_proj === "Ano" && (
                          <div className="exclusive-badge position-absolute top-0 start-0 bg-info text-white p-2">
                            DEVELOPERSKÝ PROJEKT
                          </div>
                        )}
                      </div>

                      <div className="card-body content p-4 bg-lightblue">
                        <span className={`badge ${getStatusClassName(status)}`}>
                          {status}
                        </span>{" "}
                        <span className="badge bg-primary">{sell_rent}</span>
                        <h5 className="fs-5 text-dark fw-medium mt-3">
                          {property_name
                            ? `${property_name.slice(0, 33)}${
                                property_name.length > 33 ? "..." : ""
                              }`
                            : "Neznámé jméno nemovitosti"}
                        </h5>
                        <p className="text-muted mb-2">
                          {property_adress ? (
                            <>
                              {property_adress.street && (
                                <>
                                  {property_adress.street}
                                  {property_adress.city && ", "}
                                </>
                              )}
                              {property_adress.city && (
                                <>
                                  {property_adress.city}
                                  {property_adress.psc && ", "}
                                </>
                              )}
                              {property_adress.psc}
                            </>
                          ) : (
                            " "
                          )}
                        </p>
                        {property_price && (
                          <p className="fw-medium mb-0">
                            {new Intl.NumberFormat("cs-CZ", {
                              style: "currency",
                              currency: "CZK",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(property_price)}
                          </p>
                        )}
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}

          <div className="text-center mt-4">
            <Link to="/nabidka" className="btn btn-primary">
              Zobrazit všechny nemovitosti
            </Link>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
